import { BlogSection, SubscribeEmail } from "Components/Own";

import Section1 from "./Section1";
import Section2 from "./Section2";

import TitleComponent from "./CustomTitle.Component";
import WorkWithUs from "./WorkWithUs";
import { _trim } from "Utils/Lodash.Util";

const BlogDetail = ({
  currentBlog,
  relatedBlogs,
}: {
  relatedBlogs: any[];
  currentBlog: any;
}) => {
  return (
    <div>
      <Section1 data={currentBlog} />
      <Section2
        title={currentBlog.title?.rendered}
        content={_trim(currentBlog.content?.rendered).replace(/\r?\n|\r/g, "")}
      />

      <BlogSection
        blogs={relatedBlogs}
        CustomTitle={TitleComponent}
        CustomButton={WorkWithUs}
        showAllBlog
        containerClassName="bg-white"
      />
      <SubscribeEmail />
    </div>
  );
};

export default BlogDetail;
